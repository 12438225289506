import { financeApi } from '../../api';

export interface createProductHQ {
  error?: string;
  products: Product[];
  payableWith: string[];
  feeResponsible: string;
  creditCardInstallments: number;
  physicalProduct: boolean;
}

export interface Product {
  id: string;
  value: number;
}

export async function createCheckoutHQ(data: any): Promise<createProductHQ> {
  try {
    const response = await financeApi.post<createProductHQ>(
      `/checkouts/HQ`,
      data,
    );
    const product = response.data;

    return product;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o checkout, atualize a página e tente novamente 😕`,
      err,
    );

    const errorMessage =
      err?.response?.data?.message ===
      'Payment Provider Account is not setup for this unit'
        ? 'Conta de pagamento não encontrada para essa unidade, escolha outra ou use o ID da conta IUGU'
        : 'Ocorreu um erro ao criar o checkout';

    return {
      error: errorMessage,
    } as createProductHQ;
  }
}
