import React, { useRef } from 'react';
import {
  FiArrowLeft,
  FiCreditCard,
  FiLink,
  FiShoppingCart,
} from 'react-icons/fi';
import { FiX } from 'react-icons/fi';
import { Button, Headline } from '../../../LegacyV4Components';

import { Modal } from '../../../Default/Modal';

import * as S from './styles';
import { useToast } from '../../../../hooks/toast';
import { createCheckoutHQ } from '../../../../services/requests/Finance/createCheckoutHQ';
import { useGetProductById } from '../../../../services/requests/Finance/getProductById';

export interface CheckoutProps {
  id: string;
  creditCardInstallments: number;
  feeResponsible: string;
  payableWith: string[];
  disabled: boolean;
  products: {
    _id: string;
    createdAt: string;
    description: string;
    name: string;
    physicalProduct: boolean;
    productType: string;
  }[];
}

export function ResumeCheckoutHQModal({
  isShown,
  setIsShown,
  data,
  setIsOpenResume,
}: {
  isShown: boolean;
  setIsShown: (value: React.SetStateAction<boolean>) => void;
  data: any;
  setIsOpenResume: (value: React.SetStateAction<boolean>) => void;
}) {
  const sendFormRef = useRef<HTMLButtonElement>(null);
  const { addToast } = useToast();
  const { data: product } = useGetProductById(data.products[0].id);

  async function handleSubmit() {
    // const numberedValue = Number(
    //   data.products[0].value.toString().replace(',', '.'),
    // );
    const dataToSend = {
      ...data,
      // value: numberedValue * 100,
    };

    const createdProduct = await createCheckoutHQ(dataToSend);
    !createdProduct?.error &&
      addToast({
        title: 'Sucesso',
        description: 'Checkout criado com sucesso',
        type: 'success',
      });
    !!createdProduct.error &&
      addToast({
        title: 'error',
        description: createdProduct.error,
        type: 'error',
      });
    sendFormRef.current?.click();
    !createdProduct.error && setIsOpenResume(false);
  }

  return (
    <>
      <Modal modalOpen={isShown} onBackground={() => setIsShown(false)}>
        <S.ModalWrapper>
          <S.ModalHeader>
            <Headline variant="h4">Resumo do checkout</Headline>
            <button
              type="button"
              onClick={() => setIsOpenResume(false)}
              aria-label="default"
            >
              <FiX />
            </button>
          </S.ModalHeader>
          <S.ModalBody>
            <S.SectionResume>
              <Headline variant="h5">
                <FiShoppingCart />
                {}
                Produto
              </Headline>
              <p>Nome do produto: {product?.data.name}</p>
              <p>Descrição: {product?.data.description}</p>
              <p>Valor: R${data.products[0].value}</p>
            </S.SectionResume>

            <S.SectionResume>
              <Headline variant="h5">
                <FiCreditCard />
                Formas de pagamento
              </Headline>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {data.payableWith.map((productLists: any) => {
                  switch (productLists) {
                    case 'credit_card':
                      return ` - Cartão ${data?.creditCardInstallments}x`;
                    case 'bank_slip':
                      return ' - Boleto';
                    case 'pix':
                      return ' - Pix';
                    default:
                      break;
                  }
                  return <p>{productLists}</p>;
                })}
              </div>
              {/* <p>Boleto</p>
              <p>Cartão 12x</p> */}
            </S.SectionResume>
          </S.ModalBody>
          <S.ModalFooter>
            <Button
              variant="primary"
              color="green"
              size="default"
              onClick={handleSubmit}
            >
              Gerar link
              <FiLink color="#333333" />
            </Button>
            <Button
              variant="secondary"
              color="green"
              size="default"
              onClick={() => {
                setIsOpenResume(false);
                setIsShown(true);
              }}
            >
              <FiArrowLeft color="#00C49A" />
              Voltar para edição
            </Button>
          </S.ModalFooter>
        </S.ModalWrapper>
      </Modal>
    </>
  );
}
